<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <v-card-title>
        <div v-if="item" class="headline">
          {{ $t("common.api-tokens.editDialog.title") }}
        </div>
        <div v-else class="headline">
          {{ $t("common.api-tokens.addDialog.title") }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="ma-2" no-gutters>
            <!-- Label -->
            <v-col cols="12">
              <v-text-field
                :label="$t('common.api-tokens.labels.label')"
                outlined
                :rules="requiredRules"
                v-model="form.label"
                maxlength="20"
              />
            </v-col>

            <!-- Account -->
            <v-col cols="12" v-if="!accountId">
              <SelectAccount
                :value="form.account"
                :is-required="true"
                @input="onInputAccount"
              />
            </v-col>

            <v-col cols="12" class="mt-4">
              <AlertBar />
            </v-col>

            <v-col cols="12">
              <ButtonBarFormDialog
                class="mt-5"
                :isLoadingBtnSave="isLoading.btnSave"
                v-on:cancel="close(false)"
                v-on:submit="customSubmit"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ApiTokenMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectAccount: () => import("@/components/Common/Inputs/SelectAccount"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    accountId: { type: String, default: null },
  },

  data() {
    return {
      valid: null,
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        label: "",
        account: null,
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;
        this.shortcutFormObjects(["account"]);
        if (this.item) this.edit();
        else this.add();
      }
    },

    add() {
      this.$http
        .post(
          `/accounts/${this.accountId ?? this.form.account}/api-tokens`,
          {
            ...this.form,
          },
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("common.api-tokens.addDialog.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    edit() {
      this.$http
        .put(
          `/accounts/${this.accountId ?? this.form.account}/api-tokens/${
            this.item.id
          }`,
          {
            ...this.form,
          },
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("common.api-tokens.editDialog.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputAccount(val) {
      this.form.account = val;
    },
  },
};
</script>

<style scoped></style>
